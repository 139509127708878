var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        width: "280",
        placement: "top",
        trigger: "hover",
        "popper-class": "sharePopover"
      }
    },
    [
      _c("div", { staticClass: "shareContainer" }, [
        _c("div", { staticClass: "shareItem" }, [
          _c("span", {
            staticClass: "iconfont_Me icon-icon-test2 iconStyle",
            style: { color: "#EF4B4B" },
            attrs: { "data-sitename": "weibo" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.selectedShare.apply(null, arguments)
              }
            }
          })
        ]),
        _c("div", { staticClass: "shareItem" }, [
          _c("span", {
            staticClass: "iconfont_Me icon-icon-test1 iconStyle",
            style: { color: "#0077B5" },
            attrs: { "data-sitename": "linkedin" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.selectedShare.apply(null, arguments)
              }
            }
          })
        ]),
        _c("div", { staticClass: "shareItem" }, [
          _c("span", {
            staticClass: "iconfont_Me icon-icon-test iconStyle",
            style: { color: "#3375CB" },
            attrs: { "data-sitename": "facebook" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.selectedShare.apply(null, arguments)
              }
            }
          })
        ]),
        _c("div", { staticClass: "shareItem" }, [
          _c("span", {
            staticClass: "iconfont_Me icon-icon-test6 iconStyle",
            style: { color: "#00BDF3" },
            attrs: { "data-sitename": "twitter" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.selectedShare.apply(null, arguments)
              }
            }
          })
        ]),
        _c("div", {
          staticClass: "shareItem melinkedIcon",
          attrs: { "data-sitename": "melinked" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.selectedShare.apply(null, arguments)
            }
          }
        }),
        _c("div", {
          staticClass: "shareItem copyIcon",
          attrs: { "data-sitename": "copy" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.selectedShare.apply(null, arguments)
            }
          }
        })
      ]),
      _vm._t("default", null, { slot: "reference" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }