<template>
  <div class="commentOutter" :style="{ 'border-bottom': '1px solid #F7F7F7' }">
    <div class="newCommentBlock" v-if="children">
      <div class="avatarBlock">
        <Avatar
          :providerId="children.reviewerId"
          :international="
            children.reviewerCountry && children.reviewerCountry !== ''
              ? Number(children.reviewerCountry)
              : 0
          "
          :path="children.userAvatar"
          :popover="popover"
        >
        </Avatar>
      </div>
      <div class="commentInfo">
        <div class="commentAuthor" style="display:flex;align-items: center;">
          <el-tooltip
            effect="dark"
            :content="children.reviewer"
            placement="top"
          >
            <span class="childrenName" :class="isVip ? 'vipName' : ''">
              {{ children.reviewer }}
            </span>
          </el-tooltip>
          <el-image
            v-if="isVip"
            style="width: 17px; height: 14px;"
            :src="require('../../../assets/images/pay/masonry.png')"
            fit="fill"
          >
          </el-image>
        </div>
        <div class="commentcontent" @click.stop="$emit('clickCommentBlock')">
          <div
            class="childrenBox"
            :style="{
              'max-height': childrenExpandFlag || !parent ? '' : '53px',
            }"
          >
            <div
              class="childrenComment"
              ref="childrenComment"
              :class="
                childrenExpandButtonFlag && !childrenExpandFlag && parent
                  ? 'twolineComment'
                  : ''
              "
              :style="{
                'white-space': 'pre-wrap',
              }"
              v-if="childrenContent != ''"
              v-html="$emoReplaceToImage(hyperlinksCheck(childrenContent))"
            ></div>
            <div
              class="expandButton"
              @click.stop="expandChildrenComment"
              v-show="childrenExpandButtonFlag && parent"
            >
              <span
                class="iconfont_Me iconStyle"
                :class="childrenExpandFlag ? 'icon-up' : 'icon-down'"
              ></span>
            </div>
          </div>
          <div
            class="childrenCommentImg"
            v-if="children.imgUrl && children.imgUrl !== ''"
          >
            <div
              class="pictureItem"
              @click.stop="
                () => {
                  return;
                }
              "
            >
              <el-image
                style="width: 100%; height: 100%"
                :src="children.imgUrl"
                fit="cover"
                :preview-src-list="[children.imgUrl]"
              >
              </el-image>
            </div>
          </div>
          <div class="parentInfo" v-if="parent">
            <div class="parentAuthor">
              <el-tooltip
                effect="dark"
                :content="parent.reviewer"
                placement="top"
              >
                <span>{{ parent.reviewer }}</span>
              </el-tooltip>
            </div>
            <div class="parentAvatarAndComment">
              <div class="parentAvatar">
                <Avatar
                  :providerId="parent.reviewerId"
                  :international="
                    parent.reviewerCountry && parent.reviewerCountry !== ''
                      ? Number(parent.reviewerCountry)
                      : 0
                  "
                  :path="parent.userAvatar"
                  :popover="popover"
                >
                </Avatar>
              </div>
              <div class="innerCommentBlock">
                <div
                  class="parentBox"
                  :style="{ 'max-height': parentExpandFlag ? '' : '53px' }"
                >
                  <div
                    class="expandButton"
                    @click.stop="expandParentComment"
                    v-show="parentExpandButtonFlag"
                  >
                    <span
                      class="iconfont_Me iconStyle"
                      :class="parentExpandFlag ? 'icon-up' : 'icon-down'"
                    ></span>
                  </div>
                  <div
                    class="parentComment"
                    ref="parentComment"
                    :class="
                      parentExpandButtonFlag && !parentExpandFlag
                        ? 'twolineComment'
                        : ''
                    "
                    :style="{
                      'white-space': 'pre-wrap',
                    }"
                    v-if="parentContent != ''"
                    v-html="$emoReplaceToImage(hyperlinksCheck(parentContent))"
                  ></div>
                </div>
                <div
                  class="parentCommentImg"
                  v-if="parent.imgUrl && parent.imgUrl !== ''"
                >
                  <div
                    class="pictureItem"
                    @click.stop="
                      () => {
                        return;
                      }
                    "
                  >
                    <el-image
                      style="width: 100%; height: 100%"
                      :src="parent.imgUrl"
                      fit="cover"
                      :preview-src-list="[parent.imgUrl]"
                    >
                    </el-image>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CommentFooter
          :comment="children"
          :type="getCommentsParam.type"
          @replyToThisPerson="replyToThisPerson"
        ></CommentFooter>
        <CommentInputBox
          ref="commentInputBox"
          class="commentInputBox"
          v-if="openInput"
          :showAvatar="true"
          @triggerSubmit="triggerSubmit"
          :autoHeight="false"
          :sendLoading="sendLoading"
          @resetLayout="$emit('resetLayout')"
          :withPictures="true"
          :answerSomeone="comment.reviewer"
        ></CommentInputBox>
      </div>
    </div>
  </div>
</template>
<script>
import Avatar from "../avatarDisplay";
import CommentFooter from "./commentFooter";
import CommentInputBox from "./commentInputBox.vue";
import PicturePreview from "../picturePreview/basis";
import { addNewReview } from "@/api/newVersion";
export default {
  name: "NewCommentBlock",
  components: {
    Avatar,
    CommentFooter,
    CommentInputBox,
    PicturePreview,
  },
  data() {
    return {
      isVip: false,
      // 展示评论输入框
      displayTheInputBox: false,
      sendLoading: false,
      childrenExpandFlag: false,
      childrenExpandButtonFlag: false,
      parentExpandFlag: false,
      parentExpandButtonFlag: false,
    };
  },
  props: {
    // 主评论内容
    comment: {
      type: Object,
      default: () => {
        return {};
      },
    },
    popover: {
      type: Boolean,
      default: true,
    },
    // 是否可以关闭或打开输入框
    showInput: {
      type: Boolean,
      default: false,
    },
    // 评论列表参数
    getCommentsParam: {
      type: Object,
      default: () => {
        return {
          // 点赞类型(1:service(服务)，2:jobs(需求),3:people(个人)，4:group(群组), 5:enterprise(企业))
          type: 1,
          // 是否是用弹窗查看更多评论
          popupDisplay: false,
        };
      },
    },
    showBorder: {
      type: Boolean,
      default: false,
    },
    // 默认开启输入框
    openCommentInput: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    // 监听评论改变，重置数据
    "comment.reviewId": {
      handler(val, old) {
        if (val && old && val !== old) {
          Object.assign(this.$data, this.$options.data.call(this));
        }
      },
      deep: true,
      immediate: true,
    },
    openCommentInput: {
      handler(val, old) {
        if (val) {
          this.displayTheInputBox = true;
        }
      },
      deep: true,
      immediate: true,
    },
    // 监听输入框打开状态
    displayTheInputBox: {
      handler(val, old) {
        if (val && val !== old) {
          this.$store.commit("setShowCommentInputInPostInfo", false);
          this.$store.commit(
            "setCommentInputIsOpen",
            `commentInput*${this.children.reviewId}`
          );
          this.$nextTick(async () => {
            await this.setInputContent();
          });
        } else if (old && val !== old) {
          this.saveInputContent();
        }
      },
      deep: true,
    },
    // 监听首页全局输入框是否只有自己打开，如果开启了其他输入框，则关闭自己
    "$store.state.homePage.commentInputIsOpen": {
      handler() {
        if (
          this.$store.state.homePage.commentInputIsOpen !==
          `commentInput*${this.children.reviewId}`
        ) {
          this.displayTheInputBox = false;
          this.$nextTick(() => {
            this.$emit("resetLayout");
          });
        }
      },
      deep: true,
    },
  },
  computed: {
    openInput() {
      return this.displayTheInputBox && this.showInput;
    },
    parent() {
      return this.comment &&
        this.comment.parent &&
        this.comment.parent.length !== 0
        ? this.comment.parent[0]
        : null;
    },
    children() {
      return this.comment;
    },
    // 父评论的内容
    parentContent() {
      return this.parent ? this.reconvert(this.parent.content) : "";
    },
    // 子评论的内容
    childrenContent() {
      return this.children ? this.reconvert(this.children.content) : "";
    },
  },
  mounted() {
    this.redraw();
  },
  updated() {
    this.redraw();
  },
  methods: {
    saveInputContent() {
      const content = this.$refs.commentInputBox.getInputContent();
      const hasHtml =
        this.globalTrim(
          this.$refs.commentInputBox.getContentFromInput(content.innerHTML)
        ) !== "";
      const cacheOfComments = JSON.parse(
        JSON.stringify(this.$store.state.globalShare.cacheOfComments)
      );
      if (hasHtml || content.commentImage) {
        cacheOfComments[this.children.reviewId] = {
          commentImage: content.commentImage,
          innerHTML: hasHtml ? content.innerHTML : "",
          prevCursorPosition: content.prevCursorPosition,
        };
      } else {
        delete cacheOfComments[this.children.reviewId];
      }
      this.$store.commit("setCacheOfComments", cacheOfComments);
    },
    async setInputContent() {
      const cacheOfComments = JSON.parse(
        JSON.stringify(this.$store.state.globalShare.cacheOfComments)
      );
      if (cacheOfComments[this.children.reviewId]) {
        try {
          await this.$refs.commentInputBox.setInputContent(
            cacheOfComments[this.children.reviewId]
          );
        } catch (error) {}
      }
    },
    // 删除评论缓存
    deleteCommentCache() {
      const cacheOfComments = JSON.parse(
        JSON.stringify(this.$store.state.globalShare.cacheOfComments)
      );
      if (cacheOfComments[this.children.reviewId]) {
        delete cacheOfComments[this.children.reviewId];
        this.$store.commit("setCacheOfComments", cacheOfComments);
      }
    },
    // 重绘
    redraw() {
      if (
        this.$refs.childrenComment &&
        this.$refs.childrenComment.offsetHeight > 53
      ) {
        this.childrenExpandButtonFlag = true;
        this.$emit("resetLayout");
      }
      if (
        this.$refs.parentComment &&
        this.$refs.parentComment.offsetHeight > 53
      ) {
        this.parentExpandButtonFlag = true;
        this.$emit("resetLayout");
      }
    },
    // 展开子评论
    expandChildrenComment() {
      this.childrenExpandFlag = !this.childrenExpandFlag;
      this.$emit("resetLayout");
    },
    // 展开父评论
    expandParentComment() {
      this.parentExpandFlag = !this.parentExpandFlag;
      this.$emit("resetLayout");
    },
    async triggerSubmit(param) {
      this.sendLoading = true;
      let params = {
        operation: this.getCommentsParam.operation,
        reply: param.commentText,
        reviewId: this.children.reviewId,
      };
      if (param.imgUrl) {
        params.imgUrl = param.imgUrl;
      }
      let result = await addNewReview(this.getCommentsParam.id, params);
      this.sendLoading = false;
      if (result.code === 200) {
        // 记录被评论的帖子,用于刷新首页的这个帖子的最新评论
        if (this.getCommentsParam.operation == 7) {
          this.saveCommentedGigid();
        }
        // 删除评论缓存
        this.deleteCommentCache();
        this.$nextTick(async () => {
          // 重置首条评论默认展开输入框的状态
          this.$store.commit("setShowCommentInputInPostInfo", false);
          // 通知含有此条评论的评论列表刷新
          this.$store.commit("setNeedRefreshComment", this.children.reviewId);
          this.displayTheInputBox = false;
          this.$emit("resetLayout");
        });
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 发布评论后记录被评论的帖子Id
    saveCommentedGigid() {
      if (this.getCommentsParam.operation == 7) {
        const beCommentedGigids = this.$store.state.globalShare
          .beCommentedGigids;
        beCommentedGigids.push(this.getCommentsParam.id);
        this.$store.commit("setBeCommentedGigids", [
          ...new Set(beCommentedGigids),
        ]);
      }
    },
    // 将Unicode转化为评论
    reconvert(str) {
      str = str.replace(/(\\u)(\w{1,4})/gi, function($0) {
        return String.fromCharCode(
          parseInt(escape($0).replace(/(%5Cu)(\w{1,4})/g, "$2"), 16)
        );
      });
      str = str.replace(/(&#x)(\w{1,4});/gi, function($0) {
        return String.fromCharCode(
          parseInt(escape($0).replace(/(%26%23x)(\w{1,4})(%3B)/g, "$2"), 16)
        );
      });
      str = str.replace(/(&#)(\d{1,6});/gi, function($0) {
        return String.fromCharCode(
          parseInt(escape($0).replace(/(%26%23)(\d{1,6})(%3B)/g, "$2"))
        );
      });
      return str;
    },
    replyToThisPerson() {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      if (this.showInput) {
        this.displayTheInputBox = !this.displayTheInputBox;
        this.$emit("resetLayout");
      } else {
        this.$emit("startComment", true);
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.commentOutter
  width: 100%;
  overflow hidden;
  min-height 70px;
  padding-bottom: 10px;
  .newCommentBlock
    width: 100%;
    overflow hidden;
    min-height 70px;
    display flex;
    align-items flex-start;
    justify-content flex-start;
    margin-top 20px;
    .avatarBlock
      height 38px;
      width 38px;
      flex-shrink 0;
      overflow hidden;
    .commentInfo
      min-height 70px;
      flex 1;
      min-width 0;
      overflow hidden;
      box-sizing border-box;
      display flex;
      flex-flow column;
      align-items center;
      justify-content flex-start;
      padding-left 13px;
      .commentAuthor
        width 100%;
        height 20px;
        color #373737;
        cursor pointer;
        font-size 14px;
        font-weight bold;
        text-align left;
        line-height 20px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        margin-bottom: 5px;
        .childrenName
          overflow hidden;
          white-space: nowrap;
          box-sizing border-box;
          text-overflow:ellipsis;
      .commentcontent
        width: 100%;
        border-radius: 8px;
        background: #ebeef2;
        box-sizing: border-box;
        padding 12px 14px;
        .twolineComment
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        .expandButton
          width: 15px;
          height: 15px;
          flex-shrink: 0;
          border-radius: 50%;
          cursor pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          .iconStyle
            font-size 10px;
        .childrenBox
          overflow hidden;
          display: flex;
          align-items: flex-start;
          .expandButton
            margin: 4.5px 0 0 5px;
            background: #939DAC;
            .iconStyle
              color #FFFFFF;
          .childrenComment
            min-height 24px;
            color #333333;
            font-size 14px;
            line-height 24px;
            word-break:break-all;
            overflow hidden;
            white-space: pre-wrap;
        .childrenCommentImg
          width: 100%;
          height: 100px;
          text-align: left;
          overflow hidden;
          box-sizing: border-box;
          margin-top: 10px;
          .pictureItem
            width: 100px;
            height 100px;
            overflow hidden;
            border-radius 8px;
            position: relative;
            display: inline-block;
        .parentInfo
          width: 100%;
          display: flex;
          margin-top: 5px;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          .parentAuthor
            width: 100%;
            height: 20px;
            font-size: 14px;
            font-weight: 700;
            text-align: right;
            color: #373737;
            line-height: 20px;
            box-sizing: border-box;
            padding-right: 42px;
            overflow hidden;
            white-space: nowrap;
            text-overflow:ellipsis;
          .parentAvatarAndComment
            width: 100%;
            min-height: 30px;
            display: flex;
            flex-direction: row-reverse;
            align-items: flex-start;
            padding-top: 5px;
            .parentAvatar
              height: 30px;
              width: 30px;
              flex-shrink: 0;
              margin-left: 12px;
            .innerCommentBlock
              max-width: calc(100% - 42px);
              overflow hidden;
              min-height: 30px;
              text-align: right;
              border-radius: 8px;
              background: #ffffff;
              display: inline-block;
              padding 3px 12px;
              .parentBox
                overflow hidden;
                display: flex;
                align-items: flex-start;
                .expandButton
                  margin: 4.5px 5px 0 0;
                  background: #e7dede;
                  .iconStyle
                    color #939DAC;
                .parentComment
                  flex: 1;
                  min-height: 30px;
                  font-size: 14px;
                  font-weight: 400;
                  text-align: left;
                  color: #373737;
                  line-height: 24px;
                  box-sizing: border-box;
                  overflow: hidden;
                  word-break: break-all;
              .parentCommentImg
                display: block;
                max-width: 100%;
                height: 100px;
                text-align: right;
                overflow hidden;
                box-sizing: border-box;
                margin: 10px 0;
                .pictureItem
                  width: 100px;
                  height 100px;
                  overflow hidden;
                  border-radius 8px;
                  position: relative;
                  display: inline-block;
  .commentInputBox
    width 100%;
    margin-top 20px;
</style>
