<template>
  <el-popover
    width="280"
    placement="top"
    trigger="hover"
    popper-class="sharePopover"
  >
    <div class="shareContainer">
      <div class="shareItem">
        <span
          class="iconfont_Me icon-icon-test2 iconStyle"
          :style="{ color: '#EF4B4B' }"
          data-sitename="weibo"
          @click.stop="selectedShare"
        ></span>
      </div>
      <div class="shareItem">
        <span
          class="iconfont_Me icon-icon-test1 iconStyle"
          :style="{ color: '#0077B5' }"
          data-sitename="linkedin"
          @click.stop="selectedShare"
        ></span>
      </div>
      <div class="shareItem">
        <span
          class="iconfont_Me icon-icon-test iconStyle"
          :style="{ color: '#3375CB' }"
          data-sitename="facebook"
          @click.stop="selectedShare"
        ></span>
      </div>
      <div class="shareItem">
        <span
          class="iconfont_Me icon-icon-test6 iconStyle"
          :style="{ color: '#00BDF3' }"
          data-sitename="twitter"
          @click.stop="selectedShare"
        ></span>
      </div>
      <div
        class="shareItem melinkedIcon"
        data-sitename="melinked"
        @click.stop="selectedShare"
      ></div>
      <div
        class="shareItem copyIcon"
        data-sitename="copy"
        @click.stop="selectedShare"
      ></div>
    </div>
    <slot slot="reference"></slot>
  </el-popover>
</template>
<script>
import { env } from "@/utils";
import { Base64 } from "js-base64";
import { getShareData } from "@/api/newVersion";
export default {
  props: {
    shareConfig: {
      type: Object,
      default: () => {
        return {
          // 分享的内容类型(1:帖子,3:用户,4:群)
          share_type: "",
          // 分享的内容Id
          share_id: "",
        };
      },
    },
  },
  mounted() {},
  data() {
    return {
      // 分享所需的信息
      shareData: {},
    };
  },
  methods: {
    // 根据ID获取分享信息
    async getShareData() {
      let result = await getShareData(
        this.shareConfig.share_type,
        this.shareConfig.share_id
      );
      if (result.code == 200) {
        let shareLink = "";
        switch (this.shareConfig.share_type) {
          case 1:
            shareLink =
              "/postContentDetails/pai_" +
              Base64.encode(this.shareConfig.share_id);
            break;
          case 3:
            shareLink =
              "/personalCenter/pai_" + Base64.encode(this.shareConfig.share_id);
            break;
          case 4:
            shareLink =
              "/groupCenter/pai_" + Base64.encode(this.shareConfig.share_id);
            break;
        }
        this.shareData = {
          // 分享的链接
          shareLink: shareLink,
          // 分享的标题
          shareTitle: result.data.data.title,
          // 分享的图片地址
          shareImageSrc: result.data.data.attachment,
          // 分享出去的描述内容
          summary: result.data.data.content,
        };
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 选择这个平台进行分享
    async selectedShare(event) {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      let param = {};
      await this.getShareData();
      if (this.shareData.shareLink) {
        switch (event.target.dataset.sitename) {
          case "weibo":
            param = {
              url: env("BASEURL") + "/#" + this.shareData.shareLink,
              title: this.shareData.shareTitle,
              pic: this.shareData.shareImageSrc,
            };
            this.shareToOtherPlatforms(event.target.dataset.sitename, param);
            break;
          case "linkedin":
            param = {
              url: env("BASEURL") + "/#" + this.shareData.shareLink,
              title: this.shareData.shareTitle,
              summary: this.shareData.summary,
              source: env("BASEURL") + "/#",
            };
            this.shareToOtherPlatforms(event.target.dataset.sitename, param);
            break;
          case "facebook":
            param = {
              u: env("BASEURL") + "/#" + this.shareData.shareLink,
            };
            this.shareToOtherPlatforms(event.target.dataset.sitename, param);
            break;
          case "twitter":
            param = {
              url: env("BASEURL") + "/#" + this.shareData.shareLink,
              text: this.shareData.summary,
            };
            this.shareToOtherPlatforms(event.target.dataset.sitename, param);
            break;
          case "melinked":
            this.$emit("shareForMelinked", this.shareData);
            break;
          case "copy":
            this.$copyText(
              `${env("BASEURL")}/#${this.shareData.shareLink}`
            ).then(
              (res) => {
                this.$message({
                  type: "success",
                  message: this.$t("success"),
                });
              },
              (err) => {
                this.$message({
                  type: "error",
                  message: this.$t("copyFailed"),
                });
              }
            );
            break;
          default:
            console.log("error");
            break;
        }
      }
    },
  },
};
</script>
<style lang="stylus" scoped></style>
<style lang="stylus">
.sharePopover
  padding 12px 12px 2px 12px !important;
  .shareContainer
    width 100%;
    height auto;
    overflow hidden;
    .shareItem
      width 40px;
      height 40px;
      cursor pointer;
      text-align center;
      line-height 40px;
      overflow hidden;
      border-radius 50%;
      margin 0 12px 10px 0;
      display inline-block;
      &:nth-child(5n)
        margin-right 0;
      .iconStyle
        font-size 40px;
    .melinkedIcon
      background url('../../../assets/images/shareIcon/me.png') no-repeat;
      background-size 100% 100%;
    .copyIcon
      background url('../../../assets/images/shareIcon/copy.png') no-repeat;
      background-size 100% 100%;
</style>
