var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "commentOutter",
      style: { "border-bottom": "1px solid #F7F7F7" }
    },
    [
      _vm.children
        ? _c("div", { staticClass: "newCommentBlock" }, [
            _c(
              "div",
              { staticClass: "avatarBlock" },
              [
                _c("Avatar", {
                  attrs: {
                    providerId: _vm.children.reviewerId,
                    international:
                      _vm.children.reviewerCountry &&
                      _vm.children.reviewerCountry !== ""
                        ? Number(_vm.children.reviewerCountry)
                        : 0,
                    path: _vm.children.userAvatar,
                    popover: _vm.popover
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "commentInfo" },
              [
                _c(
                  "div",
                  {
                    staticClass: "commentAuthor",
                    staticStyle: { display: "flex", "align-items": "center" }
                  },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          effect: "dark",
                          content: _vm.children.reviewer,
                          placement: "top"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "childrenName",
                            class: _vm.isVip ? "vipName" : ""
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.children.reviewer) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    ),
                    _vm.isVip
                      ? _c("el-image", {
                          staticStyle: { width: "17px", height: "14px" },
                          attrs: {
                            src: require("../../../assets/images/pay/masonry.png"),
                            fit: "fill"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "commentcontent",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.$emit("clickCommentBlock")
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "childrenBox",
                        style: {
                          "max-height":
                            _vm.childrenExpandFlag || !_vm.parent ? "" : "53px"
                        }
                      },
                      [
                        _vm.childrenContent != ""
                          ? _c("div", {
                              ref: "childrenComment",
                              staticClass: "childrenComment",
                              class:
                                _vm.childrenExpandButtonFlag &&
                                !_vm.childrenExpandFlag &&
                                _vm.parent
                                  ? "twolineComment"
                                  : "",
                              style: {
                                "white-space": "pre-wrap"
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$emoReplaceToImage(
                                    _vm.hyperlinksCheck(_vm.childrenContent)
                                  )
                                )
                              }
                            })
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.childrenExpandButtonFlag && _vm.parent,
                                expression: "childrenExpandButtonFlag && parent"
                              }
                            ],
                            staticClass: "expandButton",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.expandChildrenComment.apply(
                                  null,
                                  arguments
                                )
                              }
                            }
                          },
                          [
                            _c("span", {
                              staticClass: "iconfont_Me iconStyle",
                              class: _vm.childrenExpandFlag
                                ? "icon-up"
                                : "icon-down"
                            })
                          ]
                        )
                      ]
                    ),
                    _vm.children.imgUrl && _vm.children.imgUrl !== ""
                      ? _c("div", { staticClass: "childrenCommentImg" }, [
                          _c(
                            "div",
                            {
                              staticClass: "pictureItem",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return (() => {
                                    return
                                  }).apply(null, arguments)
                                }
                              }
                            },
                            [
                              _c("el-image", {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: _vm.children.imgUrl,
                                  fit: "cover",
                                  "preview-src-list": [_vm.children.imgUrl]
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm.parent
                      ? _c("div", { staticClass: "parentInfo" }, [
                          _c(
                            "div",
                            { staticClass: "parentAuthor" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "dark",
                                    content: _vm.parent.reviewer,
                                    placement: "top"
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.parent.reviewer))
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "parentAvatarAndComment" }, [
                            _c(
                              "div",
                              { staticClass: "parentAvatar" },
                              [
                                _c("Avatar", {
                                  attrs: {
                                    providerId: _vm.parent.reviewerId,
                                    international:
                                      _vm.parent.reviewerCountry &&
                                      _vm.parent.reviewerCountry !== ""
                                        ? Number(_vm.parent.reviewerCountry)
                                        : 0,
                                    path: _vm.parent.userAvatar,
                                    popover: _vm.popover
                                  }
                                })
                              ],
                              1
                            ),
                            _c("div", { staticClass: "innerCommentBlock" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "parentBox",
                                  style: {
                                    "max-height": _vm.parentExpandFlag
                                      ? ""
                                      : "53px"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.parentExpandButtonFlag,
                                          expression: "parentExpandButtonFlag"
                                        }
                                      ],
                                      staticClass: "expandButton",
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.expandParentComment.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "iconfont_Me iconStyle",
                                        class: _vm.parentExpandFlag
                                          ? "icon-up"
                                          : "icon-down"
                                      })
                                    ]
                                  ),
                                  _vm.parentContent != ""
                                    ? _c("div", {
                                        ref: "parentComment",
                                        staticClass: "parentComment",
                                        class:
                                          _vm.parentExpandButtonFlag &&
                                          !_vm.parentExpandFlag
                                            ? "twolineComment"
                                            : "",
                                        style: {
                                          "white-space": "pre-wrap"
                                        },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$emoReplaceToImage(
                                              _vm.hyperlinksCheck(
                                                _vm.parentContent
                                              )
                                            )
                                          )
                                        }
                                      })
                                    : _vm._e()
                                ]
                              ),
                              _vm.parent.imgUrl && _vm.parent.imgUrl !== ""
                                ? _c(
                                    "div",
                                    { staticClass: "parentCommentImg" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "pictureItem",
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return (() => {
                                                return
                                              }).apply(null, arguments)
                                            }
                                          }
                                        },
                                        [
                                          _c("el-image", {
                                            staticStyle: {
                                              width: "100%",
                                              height: "100%"
                                            },
                                            attrs: {
                                              src: _vm.parent.imgUrl,
                                              fit: "cover",
                                              "preview-src-list": [
                                                _vm.parent.imgUrl
                                              ]
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ])
                        ])
                      : _vm._e()
                  ]
                ),
                _c("CommentFooter", {
                  attrs: {
                    comment: _vm.children,
                    type: _vm.getCommentsParam.type
                  },
                  on: { replyToThisPerson: _vm.replyToThisPerson }
                }),
                _vm.openInput
                  ? _c("CommentInputBox", {
                      ref: "commentInputBox",
                      staticClass: "commentInputBox",
                      attrs: {
                        showAvatar: true,
                        autoHeight: false,
                        sendLoading: _vm.sendLoading,
                        withPictures: true,
                        answerSomeone: _vm.comment.reviewer
                      },
                      on: {
                        triggerSubmit: _vm.triggerSubmit,
                        resetLayout: function($event) {
                          return _vm.$emit("resetLayout")
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }